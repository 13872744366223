<script setup>
const props = defineProps({
  source: {
    type: String,
    default: () => '',
  },
});

const { $tracking } = useNuxtApp();

// ### TRACKING
const trackBannerClick = function () {
  $tracking.onCampaignBannerClick(props.source);
};
</script>

<template>
  <div class="seasonal-deals-banner">
    <div class="inner-container">
      <a
        class="sd-banner-link"
        href="https://global.hostelworld.com/global-travel-deals"
        rel="noopener"
        target="_blank"
        :title="$t('t_SDDEALSTITLE')"
        @click="trackBannerClick"
      >
        <h2 class="sd-banner-title">{{ $t('t_SDDEALSTITLE') }}</h2>
        <div class="sd-banner-text-container">
          <p>
            {{ $t('t_SDDEALSSUBTITLE') }}

            <a
              class="deal-link-tc link link-2-reg"
              href="https://global.hostelworld.com/global-travel-deals#terms-and-conditions"
              rel="noopener"
              :title="$t('t_TCAPPLY')"
              target="_blank"
            >{{ $t('t_TCAPPLY') }}</a>
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.seasonal-deals-banner {
  margin-bottom: $wds-spacing-m;
  color: $wds-color-white;

  .inner-container {
    padding: $wds-spacing-s $wds-spacing-m;
    border-radius: $wds-border-radius-m;
    background: linear-gradient(261deg, #f6a90e -0.59%, #ff714b 18.09%, #cc0074 98.1%),
      linear-gradient(254deg, #ff714b -0.53%, #cc0074 64.2%);
    text-decoration-line: none;

    .sd-banner-link {
      color: $wds-color-white;
      text-decoration-line: none;
    }

    .sd-banner-text-container {
      @include body-2-bld;

      display: block;
      color: $wds-color-white;
      padding-right: $wds-spacing-s;

      @include tablet {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .sd-banner-title {
      @include title-4-bld;

      display: block;
      color: $wds-color-white;
      padding: $wds-spacing-s $wds-spacing-m $wds-spacing-s 0;
      white-space: nowrap;

      @include tablet {
        display: inline-block;
        font-size: $wds-font-size-title-3;
        vertical-align: middle;
      }
    }

    .deal-link-tc {
      display: inline-block;
      color: $wds-color-white;
      text-decoration-line: none;
    }
  }
}
</style>
