<script setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  img: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '',
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: '',
  },
  height: {
    type: String,
    default: '',
  },
});

const teal = computed(() => props.bgColor === 'teal');
const blue = computed(() => props.bgColor === 'blue');
const sunset = computed(() => props.bgColor === 'sunset');
</script>

<template>
  <div
    class="card"
    :class="{ inverted, teal, blue, sunset }"
  >
    <div class="card-tag">
      <h2>{{ props?.title }}</h2>
      <p>{{ props?.subtitle }}</p>
    </div>
    <img
      :alt="props?.title"
      :src="props?.img"
      :width="props?.width"
      :height="props?.height"
      loading="lazy"
    />
  </div>
</template>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: $wds-spacing-l;
  min-height: wds-rem(330px);
  border-radius: $wds-spacing-m;

  &.teal {
    background-color: $wds-color-teal-light;
  }

  &.sunset {
    background-color: $wds-color-sunset-light;
  }

  &.blue {
    background-color: $wds-color-blue;
  }

  @include tablet-large {
    min-height: wds-rem(450px);
  }

  @include desktop {
    min-height: wds-rem(498px);
  }

  .card-tag {
    background-color: $wds-color-white;
    align-self: flex-start;
    padding: $wds-spacing-xs $wds-spacing-s;
    position: relative;
    margin-right: $wds-spacing-l;
    width: fit-content;
    clip-path: polygon(0 0, 100% 0, calc(100% - 4px) 100%, 0 100%);
    padding-right: wds-rem(12px);
    left: wds-rem(-1px);
    margin-bottom: $wds-spacing-s;

    h2 {
      @include body-2-bld;
    }

    p {
      @include body-3-reg;
    }

    @include desktop {
      h2 {
        @include title-5-bld;
      }

      p {
        @include body-2-reg;
      }
    }
  }

  &.inverted {
    flex-direction: column-reverse;
    padding-top: 0;
    padding-bottom: $wds-spacing-l;

    .card-tag {
      margin-bottom: 0;
      margin-top: $wds-spacing-s;
    }
  }
}
</style>
