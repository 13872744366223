<script setup>
import { WdsIllustration } from '@wds/components';

const testingStore = useTestingStore();

// @TODO - STATICWEB-311 - Remove all when experiment is complete
const isInventoryToggleDisplayed = testingStore.hasEnabledFeature('web_3PITogglePaintedDoorTest');
const isInventoryToggleDefaultOn = computed(
  () => testingStore.getFeatureVariables('web_3PITogglePaintedDoorTest')?.variation_type === 'on_default',
);
</script>

<template>
  <div class="hero-container">
    <div class="hero-image">
      <div class="hero-headline">
        <h1 class="hero-headline-title">
          {{ $t('t_SOCIALHEADERTITLE') }}
        </h1>
        <span class="hero-headline-subtitle">{{ $t('t_SOCIALHEADERSUBTITLE') }}</span>
        <img
          class="hero-headline-arrow"
          src="https://a.hwstatic.com/image/upload/f_auto,q_auto/pwa/arrow.svg"
          alt="Start searching arrow"
          loading="lazy"
          width="170"
          height="69"
        />
      </div>
      <div class="hero-social-chat">
        <img
          class="social-chat-avatars"
          src="https://a.hwstatic.com/image/upload/f_auto,q_auto,h_277/pwa/hero.chats.png"
          alt="Users avatars"
          loading="lazy"
          width="425"
          height="277"
        />
      </div>
    </div>
    <div
      class="hero-search-container"
      :class="{ 'search-container-with-inventory-toggle': isInventoryToggleDisplayed }"
    >
      <div class="hero-search-bar">
        <HomepageHwSearchForm
          :is-inventory-toggle-displayed="isInventoryToggleDisplayed"
          :is-inventory-toggle-default-on="isInventoryToggleDefaultOn"
        />
      </div>
      <div class="hero-free-canx-notice">
        <WdsIllustration
          asset="calendar-confirmation"
          folder="sm"
          width="25"
          height="24"
          loading="lazy"
        />
        <div v-html="$t('t_FREECANXHP')" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$hero-image-host: 'https://a.hwstatic.com/image/upload/v1675960377/pwa/';
$hero-image-mobile: $hero-image-host + 'bg.mobile.svg';
$hero-image-desktop: $hero-image-host + 'bg.desktop.svg';

.hero-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .hero-image {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: $wds-color-ink-lighter;
    background-image: url($hero-image-mobile);
    border-radius: $wds-border-radius-xl;
    padding: $wds-spacing-l $wds-spacing-l wds-rem(56px) $wds-spacing-l;
    display: flex;
    flex-direction: column;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: $wds-spacing-xl;
      inset-inline: $wds-spacing-m;
      bottom: wds-rem(-38px);
      z-index: -1;
      background: linear-gradient(90deg, rgba($wds-color-purple-dark, 0.7) 10.67%, rgba($wds-color-pink, 0.7) 95.35%);
      filter: blur(32px);
    }

    .hero-headline {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $wds-spacing-xs;
      color: $wds-color-white;

      .hero-headline-title {
        @include title-2-bld;

        text-align: center;
      }

      .hero-headline-subtitle {
        max-width: wds-rem(300px);
        text-align: center;

        @include title-6-bld;
      }

      .hero-headline-arrow {
        display: none;
      }
    }

    .hero-social-chat {
      display: none;
    }
  }

  .hero-search-container {
    position: relative;
    top: wds-rem(-35px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .hero-search-bar {
      background-color: $wds-color-white;
      border-radius: $wds-border-radius-l;
      margin-inline: $wds-spacing-m;
      display: inline-block;
      width: calc(100% - 32px);
      padding: $wds-spacing-xs;
    }

    .hero-free-canx-notice {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $wds-spacing-s;
      margin-top: $wds-spacing-xl;
      margin-inline: auto;
      width: 100%;
      color: $wds-color-ink-darker;

      @include body-3-reg;
    }
  }

  @include tablet-large {
    .hero-image {
      background-image: url($hero-image-desktop);

      &::before {
        inset-inline: wds-rem(40px);
        bottom: -$wds-spacing-s;
      }
    }

    .hero-search-container {
      z-index: calc($wds-z-index-dropdown - 20);

      .hero-search-bar {
        max-width: wds-rem(1024px);
        width: calc(100% - 80px);

        :deep(.inline-form) {
          border: none;
        }
      }
    }
  }

  @include desktop {
    .hero-image {
      padding: wds-rem(56px) wds-rem(56px) $wds-spacing-xxxl;
      flex-direction: row;
      justify-content: space-between;

      &::before {
        bottom: -$wds-spacing-l;
      }

      .hero-headline {
        min-height: wds-rem(277px);
        max-width: calc(100% - 320px);
        align-items: flex-start;
        justify-content: center;

        .hero-headline-title {
          text-align: left;

          @include lg-title-3-bld;
        }

        .hero-headline-subtitle {
          max-width: initial;
          text-align: left;

          @include title-4-bld;
        }

        .hero-headline-arrow {
          display: block;
          margin-top: $wds-spacing-s;
          margin-left: 17%;
        }
      }

      .hero-social-chat {
        display: block;
        position: absolute;
        right: wds-rem(40px);

        .social-chat-avatars {
          height: 100%;
        }
      }
    }

    .hero-search-container {
      top: wds-rem(-42px);

      &.search-container-with-inventory-toggle {
        top: -$wds-spacing-xxxl;
      }

      .hero-free-canx-notice {
        @include body-2-reg;
      }
    }
  }
}
</style>
