<template>
  <CommonHwNavBar :show-list-property-pill="true" />
  <HomepageHwHero class="homepage-hero" />
</template>

<style scoped lang="scss">
.homepage-navbar {
  padding: $wds-spacing-m;

  .list-your-property {
    display: flex;
    justify-content: flex-end;

    .list-your-property-button {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.homepage-hero {
  padding: 0 $wds-spacing-s;
}

@include tablet-large {
  .homepage-navbar {
    padding: $wds-spacing-s $wds-spacing-l;
  }

  .homepage-hero {
    padding: 0 $wds-spacing-l;
  }
}

@include desktop {
  .homepage-navbar {
    padding: $wds-spacing-m wds-rem(40px);
  }

  .homepage-hero {
    padding: 0 wds-rem(40px);
  }
}
</style>
