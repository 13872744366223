<script setup>
import { SEGMENT_PAGE_DATA } from '@/config/analytics';
import { APPDOWNLOADBANNERLINKS } from '@/config/app-download-banner-links.js';
import { PROPERTY_TYPE_DEFAULT } from '@/config/property-types';

const { $tracking } = useNuxtApp();

const {
  t,
  locale,
} = useI18n();

const { useUrl } = useUrls();
const { buildSeo } = generateSeo();
const {
  getAppDownloadBannerVariation,
  isAppDownloadBannerDismissed,
} = useAppDownloadBanner();

// ### COMPOSABLES - APIs
const { getHomePageTags } = useApiSeo();

// ### STORES
const sessionStore = useSessionStore();
const languageStore = useLanguageStore();
const testingStore = useTestingStore();

// ### VARIABLES INITIALIZATION
const locationType = 'homepage';
const propertyTypeObj = PROPERTY_TYPE_DEFAULT;
const entryPageName = 'HOMEPAGE';
const gtmPageName = 'homepage';
const gtmClassification = 'home';
const gtmQuancastPage = 'Homepage';
const segmentTracking = SEGMENT_PAGE_DATA.homepage;

const hrefUrls = useUrl.generateHrefLangAlternates('');

const pageInfoData = {
  canonicalUrl: useUrl.getBaseURL(),
  ogImage: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_600/v1647449187/pwa/hostelworld.png',
};

// ### API
const seoData = await getHomePageTags();

// ### COMPUTED
const displaySeasonalDealsBanner = computed(() => {
  return testingStore.hasEnabledFeature('static_homepageSeasonalDeals2024Banner');
});

const displayBlackFridayBanner = computed(() => {
  return testingStore.hasEnabledFeature('static_blackFridayHomepageBanner');
});

const isLanguageEn = computed(() => {
  return locale?.value === 'en';
});

const getAppDownloadDetails = computed(() => {
  return {
    page: 'homepage',
    linkPaths: {
      a: APPDOWNLOADBANNERLINKS?.HOMEPAGE?.VARIATION_A,
      b: APPDOWNLOADBANNERLINKS?.HOMEPAGE?.VARIATION_B,
    },
    linkParams: 'search',
  };
});

// @TODO - STATICWEB-311 - Remove all when experiment is complete
const variationOfThirdPartyInventoryToggle = computed(() => {
  if (!testingStore.hasEnabledFeature('web_3PITogglePaintedDoorTest')) {
    return 'control';
  }

  return testingStore.getFeatureVariables('web_3PITogglePaintedDoorTest')?.variation_type === 'on_default'
    ? 'Variation B'
    : 'variation A';
});

// ### SEO
const tradReplacements = {
  PROPERTYTYPE: t(propertyTypeObj?.transcode_s),
  PROPERTYTYPES: t(propertyTypeObj?.transcode),
  YEAR: new Date().getFullYear(),
};

const seoMetaData = buildSeo.getMetaData(seoData, pageInfoData, tradReplacements);

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  link: [
    {
      rel: 'canonical',
      href: seoMetaData?.canonicalUrl,
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_277/pwa/hero.chats.png',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/f_auto,q_auto/pwa/arrow.svg',
      type: 'image/svg+xml',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_382/v1676366319/pwa/ups1',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_382/v1676366319/pwa/usp2',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_382/v1676366319/pwa/usp3',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/raw/upload/f_auto,q_auto/wds/illustrations/sm/calendar-confirmation.svg',
      type: 'image/svg+xml',
    },
    {
      rel: 'preload',
      as: 'image',
      href: 'https://a.hwstatic.com/image/upload/v1675960377/pwa/bg.mobile.svg',
      type: 'image/svg+xml',
    },
  ].concat(hrefUrls),
}));

useSeoMeta({
  title: () => seoMetaData?.title,
  ogTitle: () => seoMetaData?.ogTitle,
  description: () => seoMetaData?.description,
  ogDescription: () => seoMetaData?.ogDescription,
  ogUrl: () => seoMetaData?.canonicalUrl,
  ogImage: () => seoMetaData?.ogImage,
  ogImageAlt: () => seoMetaData?.ogDescription,
  ogType: () => 'website',
  keywords: () => seoMetaData?.keywords,
  robots: () => 'index, follow, maxImagePreview:large',
  twitterCard: () => 'summary_large_image',
  twitterTitle: () => seoMetaData?.ogTitle,
  twitterDescription: () => seoMetaData?.ogDescription,
  twitterImage: () => seoMetaData?.ogImage,
  twitterImageAlt: () => seoMetaData?.ogDescription,
});

// ### TRACKING
sessionStore.setEntryPoint(entryPageName);

const trackingPropertyType = propertyTypeObj?.type?.toLowerCase();

const trackingInfo = {
  gtm: {
    gtmPropertyType: trackingPropertyType,
    gtmCanonicalUrl: seoMetaData?.canonicalUrl,
    gtmPageName,
    gtmClassification,
    gtmQuancastPage,
  },
  segment: {
    event_name: segmentTracking.VIEWED,
    page_type: segmentTracking.TYPE,
    google_modal_cohort: '',
    search_test_id_4: toValue(variationOfThirdPartyInventoryToggle),
  },
};

if (!isAppDownloadBannerDismissed()) {
  trackingInfo.segment.app_download_banner_cohort = getAppDownloadBannerVariation(locationType);
}

$tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);
</script>

<script>
/* eslint-disable */
import { ca } from '@/lib/channelAttribution.js';

const entryPageNameJs = 'HOMEPAGE';

if (import.meta.client) {
  ca?.setChannelAttribution(null, entryPageNameJs);
}
/* eslint-enable */
</script>

<template>
  <LazyCommonHwAppDownloadBanner v-bind="getAppDownloadDetails" />

  <header class="homepage-header">
    <HomepageHwHeader />
  </header>

  <section class="homepage-section no-spacing">
    <PromotionsHwSeasonalDealsBanner
      v-if="displaySeasonalDealsBanner"
      source="homepage"
    />

    <PromotionsHwBlackFridayBanner
      v-if="displayBlackFridayBanner"
      source="homepage"
    />
  </section>

  <HomepageHwFeaturesCarousel class="homepage-section" />

  <LazyHomepageHwConnectWithTravellers class="homepage-section" />

  <LazyHomepageHwSeeWhoIsGoingSection />

  <LazyHomepageHwSayHello class="homepage-section double-spacing" />

  <LazyHomepageHwFromSoloToSocial />

  <LazyHomepageHwRoamies v-if="isLanguageEn" />

  <LazyHomepageHwBestHostels />

  <LazyHomepageHwAppDownload />

  <LazyHomepageHwClimateNeutral v-if="isLanguageEn" />

  <LazyHomepageHwPopularPlaces />

  <LazyHomepageHwTipsAndAdventures />

  <LazyHomepageHwNewsletter />

  <LazyHomepageHwAppDownloadQr />

  <CommonHwLanguagePicker v-show="languageStore.isLanguagePickerOpen" />
</template>

<style lang="scss" scoped>
.homepage-header {
  margin-inline: auto;
  max-width: wds-rem(1440px);
}

.homepage-section {
  max-width: wds-rem(1440px);
  margin-inline: auto;
  padding-inline: $wds-spacing-s;
  margin-bottom: $wds-spacing-xxxl;

  &.no-spacing {
    margin-bottom: 0;
  }

  &.double-spacing {
    margin-block: $wds-spacing-xxxl;
  }
}

@include tablet-large {
  .homepage-section {
    padding-inline: wds-rem(24px);
  }
}

@include desktop {
  .homepage-section {
    padding-inline: wds-rem(40px);
  }
}
</style>
